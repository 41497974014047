import React, { FC, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import moment from 'moment-timezone';
import Message from '@components/chat/message';
import classNames from 'classnames/bind';
import Input from '@components/common/input';

interface IProps {
    className?: string;
    isShowSender?: boolean;
    isShowReceiver?: boolean;
    withoutInputBorder?: boolean;
}

const cx = classNames.bind(styles);

const Chat: FC<IProps> = ({
    className,
    isShowSender = true,
    withoutInputBorder = false,
    isShowReceiver = true
}) => {
    const divRef = useRef(null);

    const scrollToBottom = () => {
        if (divRef.current) {
            //@ts-ignore
            divRef.current?.scrollTo({
                //@ts-ignore
                top: divRef.current?.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    return (
        <div className={cx(styles.wrapper, [className])}>
            <div className={styles.messages_wrapper} ref={divRef}>
                <div className={styles.date_divider}>
                    <span>Chat started: {moment().format('DDD, MM.DD.YYYY')}</span>
                </div>
                <Message
                    firstName="John"
                    lastName="Livone"
                    message="Some message from the con sdf sdf sf sd sd asd fs dfs ad asddfas das dad sumer"
                    attachments={[]}
                    isShowUser={isShowReceiver}
                />
                <Message
                    firstName="Admin"
                    lastName="Adminovich"
                    message="Some message from the admin"
                    attachments={[]}
                    isSender
                    isShowUser={isShowSender}
                />
            </div>
            <div className={cx([styles.input_wrapper, { withoutInputBorder }])}>
                <Input
                    className={cx({ withoutInputBorder })}
                    full
                    placeholder="Write a message"
                    leftIcon="attach"
                    rightIcon="send"
                />
            </div>
        </div>
    );
};

export default Chat;
