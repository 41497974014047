import React, { FC, useEffect, useState } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import DatePicker from '@components/date_picker';
import Phone from '@components/phone';
import Address from '@components/address';
import styles from './styles.module.scss';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { EUserStatus, TConsumer } from '@xeppt/xeppt-sdk/types';
import { userStatuses } from '@const/index';

interface IProps {
    consumer?: TConsumer;
}

const PersonalSection: FC<IProps> = ({ consumer }) => {
    const [isEditAddress, setIsEditAddress] = useState(false);
    const form = useForm({});
    const { labelLocale } = useLocales();

    useEffect(() => {
        if (consumer) {
            form.reset({
                firstName: consumer?.profile?.firstName,
                lastName: consumer?.profile?.lastName,
                email: consumer?.email,
                birthDate: consumer?.profile?.birthDate,
                id: consumer?.id,
                createdAt: consumer?.createdAt,
                isVerified:
                    consumer?.status !== EConsumerStatus.UNVERIFIED &&
                    consumer?.status !== EConsumerStatus.UNCONFIRMED,
                status: consumer?.status,
                //@ts-ignore
                billingAddress: consumer?.billingAddress,
                phoneCode: consumer?.phoneCode,
                phoneNumber: consumer?.phoneNumber
            });
        }
    }, [consumer]);

    return (
        <div className={styles.wrapper}>
            <DataInfo label="General information" form={form}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('email')} />;
                    }}
                />
                <FormField<string | null>
                    name="birthDate"
                    renderComponent={(props) => {
                        return (
                            <DatePicker
                                readOnly
                                {...props}
                                label={labelLocale('birth_date')}
                                full
                            />
                        );
                    }}
                />
                {/*<FormField*/}
                {/*    name="occupancy"*/}
                {/*    renderComponent={(props) => {*/}
                {/*        return <Input full {...props} label={labelLocale('occupation')} />;*/}
                {/*    }}*/}
                {/*/>*/}
                <FormField
                    name="id"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('user_id')} />;
                    }}
                />
                <FormField<string | null>
                    name="createdAt"
                    renderComponent={(props) => {
                        return (
                            <DatePicker
                                readOnly
                                {...props}
                                label={labelLocale('date_created')}
                                full
                            />
                        );
                    }}
                />
                <FormField
                    name="isVerified"
                    renderComponent={(props) => {
                        return (
                            <Input readOnly full {...props} label={labelLocale('verification')} />
                        );
                    }}
                />
                <FormField
                    name="status"
                    renderComponent={({ value, ...props }) => {
                        return (
                            <Input
                                readOnly
                                full
                                {...props}
                                label={labelLocale('status')}
                                value={
                                    userStatuses.find(
                                        //@ts-ignore
                                        (item) => item.value === (value as EUserStatus)
                                    )?.label
                                }
                            />
                        );
                    }}
                />
                <Phone readOnly label={labelLocale('phone')} isForm />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input readOnly full {...props} label={labelLocale('email')} />;
                    }}
                />
            </DataInfo>
            <DataInfo
                label="Billing address"
                showEdit
                isEdit={isEditAddress}
                form={form}
                full
                onChangeEdit={() => setIsEditAddress((state) => !state)}>
                <Address isFull name="billingAddress" disabled={!isEditAddress} />
            </DataInfo>
        </div>
    );
};

export default PersonalSection;
