import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import Status from '@components/common/status';
import moment from 'moment-timezone';
import Chat from '@components/chat';
import classNames from 'classnames/bind';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const cx = classNames.bind(styles);

const ChatDrawer: FC<IProps> = ({ isOpen, onClose }) => {
    return (
        <div className={cx([styles.wrapper, { isOpen }])}>
            <div className={styles.drawer}>
                <div className={styles.header_actions}>
                    <button onClick={onClose}>
                        <Icon name="close" />
                    </button>
                    <Button variant="outlined" size="normal" leftIcon="check">
                        Reopen
                    </Button>
                </div>
                <div className={styles.header_wrapper}>
                    <div className={styles.left_header}>
                        <div className={styles.request_data}>
                            <Typography variant="h5" weight="bold" className={styles.title}>
                                Request #9
                            </Typography>
                            <Status variant="warning">Open</Status>
                        </div>
                        <div className={styles.header_user}>
                            <div className={styles.user}>
                                <div className={styles.avatar}>CW</div>
                                <Typography variant="body3">Cameron Williamson</Typography>
                            </div>
                            <Typography variant="body3" className={styles.date}>
                                {moment().format('MM.DD.YYYY, HH:mm')}
                            </Typography>
                        </div>
                    </div>
                    <Typography variant="body3" className={styles.subject}>
                        Unable to Link Bank Account or Credit Card
                    </Typography>
                    <div className={styles.admin}>
                        <Typography variant="body3">Assigned to</Typography>
                        <div className={styles.avatar}>CW</div>
                        <Typography variant="body3">Admin adminovich</Typography>
                        <Icon name="edit" />
                    </div>
                </div>
                <Chat />
            </div>
            {isOpen && <div onClick={onClose} className={styles.overlay} />}
        </div>
    );
};

export default ChatDrawer;
