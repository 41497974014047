import React, { FC, forwardRef } from 'react';
import { getFirstLetter } from '@utils/index';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Info from '@components/common/info';

interface IProps {
    avatar?: string;
    firstName: string;
    lastName: string;
    message: string;
    attachments?: string[];
    className?: string;
    isSender?: boolean;
    isShowUser?: boolean;
}

const cx = classNames.bind(styles);

const Message: FC<IProps> = ({
    avatar,
    message,
    attachments = [],
    isSender,
    firstName,
    lastName,
    isShowUser,
    className
}) => {
    return (
        //@ts-ignore
        <div className={cx(styles.message_container, [className, { isSender }])}>
            {isShowUser && (
                <Info variant={isSender ? 'left' : 'right'} content={`${firstName} ${lastName}`}>
                    <div className={styles.avatar}>
                        {avatar ? (
                            <img src={avatar} alt={`${firstName} ${lastName}`} />
                        ) : (
                            <p>
                                {getFirstLetter(firstName)}
                                {getFirstLetter(lastName)}
                            </p>
                        )}
                    </div>
                </Info>
            )}
            <div className={styles.message_wrapper}>
                {attachments?.length > 0 && <div className={styles.attachments}>attachments</div>}
                <div className={styles.message}>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                    <div className={styles.time}>{moment().format('HH:mm')}</div>
                </div>
            </div>
        </div>
    );
};
export default Message;
