import React, { FC, SVGAttributes } from 'react';
import Plus from './plus';
import Home from '@components/icons/home';
import Notification from '@components/icons/notification';
import NavDown from '@components/icons/nav_down';
import Close from '@components/icons/close';
import Check from '@components/icons/check';
import ArrowLeft from '@components/icons/arrow_left';
import ArrowRight from '@components/icons/arrow_right';
import Burger from '@components/icons/burger';
import CloseOutlined from '@components/icons/close_outlined';
import Reward from '@components/icons/reward';
import ErrorFilled from '@components/icons/error_filled';
import NavLeft from '@components/icons/nav_left';
import NavRight from '@components/icons/nav_right';
import SettingsFilled from '@components/icons/settings_filled';
import MoreVertical from '@components/icons/more_vertical';
import Trash from '@components/icons/trash';
import CreditCard from '@components/icons/credit_card';
import AccountBalance from '@components/icons/account_balance';
import Download from '@components/icons/download';
import MoreHorizontal from '@components/icons/more_horizontal';
import Language from '@components/icons/language';
import Search from '@components/icons/search';
import Attach from '@components/icons/attach';
import Edit from '@components/icons/edit';
import CloseWorld from '@components/icons/close_world';
import Share from '@components/icons/share';
import Clock from '@components/icons/clock';
import Calendar from '@components/icons/calendar';
import Bitcoin from '@components/icons/bitcoin';
import Sync from '@components/icons/sync';
import ClosedEye from '@components/icons/closed_eye';
import CheckOutline from '@components/icons/check_outline';
import Cloud from '@components/icons/cloud';
import Lock from '@components/icons/lock';
import Money from '@components/icons/money';
import Pin from '@components/icons/pin';
import Power from '@components/icons/power';
import LockOpened from '@components/icons/lock_opened';
import Delete from '@components/icons/delete';
import Settings from '@components/icons/settings';
import Limit from '@components/icons/limit';
import Person from '@components/icons/person';
import Send from '@components/icons/send';
import Upload from '@components/icons/upload';
import Task from '@components/icons/task';
import Warning from '@components/icons/warning';
import Interac from '@components/icons/interac';
import ErrorOutlined from '@components/icons/error_outlined';
import Headset from '@components/icons/headset';
import Atm from '@components/icons/atm';
import Bank from '@components/icons/bank';
import Link from '@components/icons/link';
import Filter from '@components/icons/filter';
import Minus from '@components/icons/minus';
import CheckCheckbox from '@components/icons/check_checkbox';
import SelectArrow from '@components/icons/select_arrow';
import OpenEye from '@components/icons/open_eye';
import ArrowUp from '@components/icons/arrow_up';
import ArrowDown from '@components/icons/arrow_down';
import Canadian from '@components/icons/canadian';
import MoneyIn from '@components/icons/money_in';
import MoneyOut from '@components/icons/money_out';
import Amex from '@components/icons/amex';
import Visa from '@components/icons/visa';
import Mastercard from '@components/icons/mastercard';
import Discover from '@components/icons/discover';
import Business from '@components/icons/business';
import List from '@components/icons/list';
import Help from '@components/icons/help';
import Consumer from '@components/icons/consumers';
import Logs from '@components/icons/logs';
import FilterBars from '@components/icons/filter_bars';
import Point from '@components/icons/point';
import Message from '@components/icons/message';
import NewMessage from '@components/icons/new_message';
import LoadingSpinner from '@components/icons/loading_spinner';
import Info from '@components/icons/info';

export type TIconType =
    | 'home'
    | 'minus'
    | 'check_checkbox'
    | 'notification'
    | 'nav_down'
    | 'close'
    | 'check'
    | 'plus'
    | 'arrow_left'
    | 'arrow_down'
    | 'arrow_up'
    | 'select_arrow'
    | 'loading_spinner'
    | 'arrow_right'
    | 'burger'
    | 'business'
    | 'close_outlined'
    | 'reward'
    | 'error_filled'
    | 'nav_left'
    | 'nav_right'
    | 'settings_filled'
    | 'more_vertical'
    | 'trash'
    | 'credit_card'
    | 'account_balance'
    | 'info'
    | 'download'
    | 'more_horizontal'
    | 'language'
    | 'search'
    | 'attach'
    | 'edit'
    | 'close_world'
    | 'share'
    | 'clock'
    | 'calendar'
    | 'bitcoin'
    | 'sync'
    | 'closed_eye'
    | 'check_outlined'
    | 'cloud'
    | 'lock'
    | 'money'
    | 'pin'
    | 'power'
    | 'lock_opened'
    | 'delete'
    | 'settings'
    | 'limit'
    | 'person'
    | 'send'
    | 'upload'
    | 'task'
    | 'warning'
    | 'interac'
    | 'error_outlined'
    | 'headset'
    | 'atm'
    | 'bank'
    | 'amex'
    | 'visa'
    | 'mastercard'
    | 'discover'
    | 'link'
    | 'open_eye'
    | 'list'
    | 'consumer'
    | 'message'
    | 'new_message'
    | 'point'
    | 'logs'
    | 'help'
    | 'filter_bars'
    | 'canadian'
    | 'money_in'
    | 'money_out'
    | 'filter';

export type TIconConfig = {
    component: FC<SVGAttributes<SVGElement>>;
};

const svgIconsConfig: Record<TIconType, TIconConfig> = {
    plus: { component: Plus },
    open_eye: { component: OpenEye },
    select_arrow: { component: SelectArrow },
    check_checkbox: { component: CheckCheckbox },
    minus: { component: Minus },
    home: { component: Home },
    notification: { component: Notification },
    nav_down: { component: NavDown },
    close: { component: Close },
    list: { component: List },
    help: { component: Help },
    consumer: { component: Consumer },
    message: { component: Message },
    new_message: { component: NewMessage },
    logs: { component: Logs },
    point: { component: Point },
    filter_bars: { component: FilterBars },
    loading_spinner: { component: LoadingSpinner },
    check: { component: Check },
    arrow_left: { component: ArrowLeft },
    arrow_right: { component: ArrowRight },
    burger: { component: Burger },
    business: { component: Business },
    close_outlined: { component: CloseOutlined },
    reward: { component: Reward },
    error_filled: { component: ErrorFilled },
    nav_left: { component: NavLeft },
    canadian: { component: Canadian },
    nav_right: { component: NavRight },
    settings_filled: { component: SettingsFilled },
    more_vertical: { component: MoreVertical },
    trash: { component: Trash },
    credit_card: { component: CreditCard },
    account_balance: { component: AccountBalance },
    download: { component: Download },
    more_horizontal: { component: MoreHorizontal },
    info: { component: Info },
    language: { component: Language },
    search: { component: Search },
    attach: { component: Attach },
    edit: { component: Edit },
    close_world: { component: CloseWorld },
    share: { component: Share },
    clock: { component: Clock },
    calendar: { component: Calendar },
    bitcoin: { component: Bitcoin },
    sync: { component: Sync },
    closed_eye: { component: ClosedEye },
    check_outlined: { component: CheckOutline },
    cloud: { component: Cloud },
    lock: { component: Lock },
    money: { component: Money },
    pin: { component: Pin },
    power: { component: Power },
    lock_opened: { component: LockOpened },
    delete: { component: Delete },
    settings: { component: Settings },
    limit: { component: Limit },
    person: { component: Person },
    send: { component: Send },
    upload: { component: Upload },
    task: { component: Task },
    warning: { component: Warning },
    interac: { component: Interac },
    error_outlined: { component: ErrorOutlined },
    headset: { component: Headset },
    atm: { component: Atm },
    bank: { component: Bank },
    link: { component: Link },
    arrow_up: { component: ArrowUp },
    arrow_down: { component: ArrowDown },
    filter: { component: Filter },
    money_in: { component: MoneyIn },
    money_out: { component: MoneyOut },
    amex: { component: Amex },
    visa: { component: Visa },
    mastercard: { component: Mastercard },
    discover: { component: Discover }
};

export interface IIcon {
    name: TIconType;
    height?: number;
    width?: number;
    className?: string;
}

export const Icon: FC<IIcon & SVGAttributes<SVGElement>> = ({
    name,
    width = 24,
    height = 24,
    ...props
}) => {
    const IconComponent = svgIconsConfig[name]?.component;

    return <IconComponent width={width} height={height} {...props} />;
};
