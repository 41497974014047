import React, { useEffect, useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useConsumers } from '@hooks/api/useConsumers';
import { EUserStatus, TConsumerLite, TUser } from '@xeppt/xeppt-sdk/types';
import { useDebounceValue } from 'usehooks-ts';
import Status from '@components/common/status';
import { getConsumerStatus, getConsumerStatusColor } from '@utils/index';
import { apiAdminService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { consumerStatuses, userStatusesUpdates } from '@const/index';
import Dropdown from '@components/common/dropdown';
import RangeDatePicker from '@components/range_date_picker';
import TicketDrawer from '@sections/ticket_drawer';
import ChatDrawer from '@sections/chat_drawer';

const TicketsLayout = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);

    const columns = [
        {
            label: 'ID',
            key: 'id',
            isSort: true
        },
        {
            label: 'Subjects',
            key: 'subject',
            filterOptions: []
        },
        {
            label: 'Requester',
            key: 'requester'
        },
        {
            label: 'Requested',
            key: 'requested',
            isSort: true
        },
        {
            label: 'Updated',
            key: 'updated',
            isSort: true
        },
        {
            label: 'Assignee',
            key: 'assignee',
            isSort: true
        },
        {
            label: 'Status',
            key: 'status'
        },
        {
            label: 'Actions',
            key: 'id',
            render: (value: string) => <Dropdown items={[]} />
        }
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Tickets</Typography>
                    <Typography>10 records found</Typography>
                </div>
                <div className={styles.actions}>
                    <RangeDatePicker
                        className={styles.date}
                        placeholder="Select a date range requested"
                    />
                    <RangeDatePicker
                        className={styles.date}
                        placeholder="Select a date range updated"
                    />
                    <Input
                        className={styles.search}
                        placeholder="Search by ID, subject, requester or assigne..."
                        leftIcon="search"
                    />
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={[]}
                onFilterChange={() => {}}
                totalRows={0}
                // onLoadData={() => refetchConsumers({ filter: consumersFilter })}
                // filter={{
                //     status: consumersFilter?.status ? consumersFilter?.status : []
                // }}
                // onSortChange={() => {
                //     handleChangeConsumersFilter({
                //         order: consumersFilter.order === 'DESC' ? 'ASC' : 'DESC'
                //     });
                // }}
                emptyDescription="Tickets list is empty"
            />
            <ChatDrawer isOpen={isOpen} onClose={() => setIsOpen((state) => !state)} />
        </div>
    );
};

export default TicketsLayout;
